import React from "react";
import { Header, Input, Segment, Checkbox, Form, Button, Modal } from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"
import Login from "./login"
import Evaluate from "./evaluate"
import AdminMenu from "./adminmenu"
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

const maxAge = 5 * 60;
const delay = (maxAge - 1) * 1000;
class Admin extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    // cookies.remove('isLogged')
    var kk = 'no';
    var ck = cookies.get('isLogged');

    console.log("!!!!", ck)

    if(cookies.get('isLogged') !== undefined) {
      console.log("!!!!", ck)

      kk = ck
    }

    console.log("!!!!", kk)

    this.state = {
      id: '',
      password: '',
      logged: kk,
      // isLogged: cookies.get('isLogged') || 'no'

    };
  }
  extendCookie = () => {
    const { cookies } = this.props;
    var kk = "no";
    var ck = cookies.get('isLogged');
    if (ck === undefined) {
      console.log("!!!!!", "tick", kk)
    }
    if (ck !== undefined)
      kk = ck
    console.log("!!!!!", "tick", kk)
    // cookies.remove('isLogged')
    cookies.set('isLogged', kk, { path: '/', maxAge: maxAge });


  }

  componentDidMount() {
    // this.setupBeforeUnloadListener();
    this.extendCookie();
    setInterval(this.extendCookie, delay)
    console.log("!!!!!", "admin mount")

  }
  componentWillUnmount() {


    console.log("!!!!!", "admin unmount")

  }
  componentDidUpdate() {
    console.log("!!!!!", "admin update")
  }
  onChange = (e) => {
    var input = ""
    var value = e.target.value
    if (e.target.id === "id")
      input = "id"
    else if (e.target.id === "password") {
      input = "password"

    }
    console.log(value)
    this.setState({
      ...this.state,
      [input]: value,
    })
  }
  logged = (b) => {
    const { cookies } = this.props;
    var cb = b?"yes":"no"

    cookies.set('isLogged', cb, { path: '/', maxAge: maxAge });

    this.setState({
      ...this.state,
      logged: cb,
    })
  }
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.root}>
          <Header as='h3'>스킨핏 피부진단</Header>
          {this.state.logged==="no" && <Login logged={this.logged}></Login>}
          {/* {this.state.logged && <Evaluate></Evaluate>} */}

          {this.state.logged==="yes" && <AdminMenu></AdminMenu>}

          {/* <div style={styles.form}>
            <Form>
              <Form.Field>
                <label>아이디</label>
                <input id="id" value={this.state.name} onChange={this.onChange} placeholder='아이디' />
              </Form.Field>
              <Form.Field>
                <label>비밀번호</label>
                <input type='password' id="password" value={this.state.password} onChange={this.onChange} placeholder='비밀번호' />
              </Form.Field>

              <Button type='submit' onClick={this.handleSubmit} style={{width:100}}>다음</Button>
            </Form>

          </div> */}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // background: '#FF00FF',
    // margin: '10px',

  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  checkboxele: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'black',
    height: 'calc(100%)',
    width: 'calc(100%)',
    maxWidth: '1200px',
    padding: '40px 10px 40px 10px',
    margin: '10px',
    alignItems: 'center',
  },
  form: {
    width: 'calc(100% - 50px)',
    maxWidth: '500px',

  }
};
export default withCookies(Admin);;
