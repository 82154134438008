import React from "react";
import { Header, Input, Segment, Checkbox, Form, Button, Modal , Image} from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"


class Done extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
 
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.root}>
          <div style={styles.header}>
            <Header as='h2' style={{color:'rgb(85,85,85)'}} >アップロードを完了しました。</Header>

          </div>
          <div style={styles.header}>

          <Header as='h3' style={{color:'rgb(119,119,119)'}}>ご利用ありがとうございます。</Header>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // background: '#FF00FF',
    // margin: '10px',

  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  checkboxele: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',

    // color: 'black',
    height: 'calc(100%)',
    width: 'calc(100%)',
    maxWidth: '500px',
    minHeight: ' 700px',
    padding: '40px 10px 40px 10px',
    margin: '10px',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    color:'rgb(119,119,119)',
    alignItems: 'flex-start',

  },
  header: {
    width: 'calc(100%)',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 20,
    marginBottom: 20,
    color:'rgb(85,85,85)'
  },
  header2: {
    width: 'calc(100%)',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 0,
    marginTop: 25,
    marginBottom: 10,
    color:'rgb(85,85,85)'
  },
  form: {
    display: 'flex',
    height: 'calc(100%)',
    width: 'calc(100%)',
    justifyContent: 'center',

    alignItems: 'flex-start',

    // width: 'calc(100% - 50px)'
  },
  formInner: {
    width:'calc(100%)', paddingLeft:20, paddingRight: 20
  },
};
export default Done;
