import React from "react";
import { Header, Input, Segment, Checkbox, Form, Modal } from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"
import { Button, Radio } from 'antd';
import 'antd/dist/antd.css';

const headers = {
  'Content-Type': 'application/json'
}
const options = {
  headers: headers,
  timeout: 5000
}

var checked = [false, false, false, true];

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buyerName: '',
      buyerNumber: '',
      userName: '',
      userNumber: '',
      check: false,
      modalOpen: false,
      modalContent: "",
      check: false,
      check1: false,
      check2: false,
      check3: true,
      checkSame: false,
    };
  }
  componentDidMount() {
    console.log("!!!!",this.props.location.pathname)
  }

  ModalNotice = () => (
    <Modal
      // trigger={<Button>Show Modal</Button>}
      // trigger={<Button>Show Modal</Button>}
      open={this.state.modalOpen}
      header='Reminder!'
      content={this.state.modalContent}
      onActionClick={this.handleModal}
      actions={[{ key: 'done', content: 'Done', positive: true }]}
    />
  )

  handleModal = (e) => {
    var txt = e.target.textContent;

    if (txt === "Done")
      console.log(e.target.textContent)

    this.setState({
      ...this.state,
      modalOpen: false,
    })
  }


  handleSubmit = (e) => {
    var content = ""
    var content3 = "Please check your name and phone number."
    var numrule = /^[0-9]{6,12}$/;
    var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;

    if (this.state.buyerName === '' || this.state.buyerNumber === ''
      || this.state.userName === '' || this.state.userNumber === '') {
      content = content3;
      this.setState({
        ...this.state,
        modalOpen: true,
        modalContent: content,
      })
    } else if(!numrule.test(this.state.buyerNumber) || !numrule.test(this.state.userNumber)) {
      content = "You can enter only numbers between 6 and 12 digits without a \"-\".";
      this.setState({
        ...this.state,
        modalOpen: true,
        modalContent: content,
      })
    } else if(special_pattern.test(this.state.buyerName) || special_pattern.test(this.state.userName)) {
      content = "Special characters cannot be entered in the name.";
      this.setState({
        ...this.state,
        modalOpen: true,
        modalContent: content,
      })
    }
    else {
      var bName = this.state.buyerName.replace(/\s+$/, '')
      var uName = this.state.userName.replace(/\s+$/, '')
      this.setState({
        ...this.state,
        buyerName: bName,
        userName: uName,
      })

      var chklist = (this.state.check ? "트러블," : "").concat(this.state.check1 ? "탄력," : "").concat(this.state.check2 ? "색소침착" : "");
      console.log("!!!!", chklist)
      this.props.history.push({
        pathname: '/pic-vn',
        state: {
          buyerName: bName, buyerNumber: this.state.buyerNumber,
          userName: uName, userNumber: this.state.userNumber, chkList: chklist
        }
      })
    }

    // this.checkValidation(this.state.name, this.state.number)
  }
  onChange = (e) => {
    var input = e.target.id; //"number"
    var value = e.target.value
    if (input === "check") {
      // input = "check"

      value = e.target.checked
    }
    console.log(e.target.checked)
    this.setState({
      ...this.state,
      [input]: value,
    })
  }
  onCheckChange = (e) => {
    var id = e.target.id
    var value = e.target.checked
    var value2 = true;
    // var [id] = value;
    var a = {
      "check": this.state.check,
      "check1": this.state.check1,
      "check2": this.state.check2,
      "check3": this.state.check3,
    }
    a = { ...a, [id]: value };
    if (id === 'check3') {
      console.log(id)
      this.setState({
        ...this.state,
        [id]: value,
        "check": false,
        "check1": false,
        "check2": false,
      })
    }
    else {
      if (!a["check"] && !a["check1"] && !a["check2"]) {
        console.log("all clear")
        value2 = true;
      } else {
        value2 = false;
      }
      console.log(id)
      this.setState({
        ...this.state,
        [id]: value,
        "check3": value2,
      })
    }



  }
  onCheckSameChange = (e) => {
    var id = e.target.id
    var value = e.target.checked
    var name, number;
    if (value) {
      name = this.state.buyerName;
      number = this.state.buyerNumber;
    } else {
      name = "";
      number = "";
    }
    console.log(id)
    this.setState({
      ...this.state,
      "checkSame": value,
      userName: name,
      userNumber: number,
    })
  }
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.root}>
          <div style={styles.header}>
            <Header as='h2' style={{ color: 'rgb(85,85,85)' }} >Kiểm tra chuẩn đoán da</Header>
          </div>
          <div style={styles.form}>
            <Form style={styles.formInner}>
              <Form.Field>
                <label style={styles.label}>Tên người mua</label>
                <input id="buyerName" value={this.state.buyerName} onChange={this.onChange} placeholder='Tên' />
              </Form.Field>
              <Form.Field>
                <label style={styles.label}>Số điện thoại</label>
                <input id="buyerNumber" value={this.state.buyerNumber} onChange={this.onChange} placeholder='số điện thoại không bao gồm dấu "-"' />
              </Form.Field>
              <div style={{ marginTop: 20, marginBottom: 10, display: 'flex', alignItems: 'flex-start', color: 'rgb(119,119,119)' }} >
                <Form.Field>

                  <Checkbox id='checkSame' label="Kiểm tra thông tin có bị trùng người mua hay không" checked={this.state.checkSame} onChange={this.onCheckSameChange} />
                  {/* <label style={styles.label}>구매자 전화번호</label> */}
                </Form.Field>

              </div>

              <Form.Field>
                <label style={styles.label}>Tên người sử dụng</label>
                <input id="userName" value={this.state.userName} onChange={this.onChange} placeholder='Tên' />
              </Form.Field>
              <Form.Field>
                <label style={styles.label}>Số điện thoại</label>
                <input id="userNumber" value={this.state.userNumber} onChange={this.onChange} placeholder='số điện thoại không bao gồm dấu "-"' />
              </Form.Field>
              <div style={styles.header3}>
              ※ Thông tin của bạn chỉ phục vụ cho mục đích nhận dạng
              </div>
              {/* <Segment raised> */}
              <div style={styles.header2}>

                <Header as='h4' style={{ color: 'rgb(119,119,119)' }}>Kiểm tra tình trạng da ( Có thể bị trùng lặp)</Header>
              </div>
              <Form.Field>
                <div style={styles.checkbox}>

                  <Checkbox id='check' label='Vấn đề về da ( nếp nhăn, tàn nhang)' checked={this.state.check} onChange={this.onCheckChange} style={{ marginTop: 10 }} />
                  <Checkbox id='check1' label='Cải thiện tình trạng da (giảm nếp nhăn, mừo vết tàn nhang))' checked={this.state.check1} onChange={this.onCheckChange} style={{ marginTop: 10 }} />
                  <Checkbox id='check2' label='Sắc tố (nám, tàn nhang, mụn v.v...)' checked={this.state.check2} onChange={this.onCheckChange} style={{ marginTop: 10 }} />
                  <Checkbox id='check3' label='Không có' checked={this.state.check3} onChange={this.onCheckChange} style={{ marginTop: 10 }} />

                </div>
              </Form.Field>
              {/* </Segment> */}

              <Button onClick={this.handleSubmit} type="text" shape="round" size='large' style={{ backgroundColor: '#555555', color: 'white', width: 'calc(100% - 30px)' }}> Tiếp theo </Button>
              {/* <Button type='submit' onClick={this.handleSubmit} style={{ width: 100 }}>다음</Button> */}
            </Form>

            {this.ModalNotice()}
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    // background: '#FF00FF',
    // margin: '10px',

  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  checkboxele: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',

    // color: 'black',
    height: 'calc(100%)',
    width: 'calc(100%)',
    maxWidth: '500px',
    padding: '40px 10px 40px 10px',
    margin: '10px',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    color: 'rgb(119,119,119)',
    alignItems: 'flex-start',

  },
  header: {
    width: 'calc(100%)',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 20,
    marginBottom: 20,
    color: 'rgb(85,85,85)'
  },
  header2: {
    width: 'calc(100%)',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 0,
    marginTop: 25,
    marginBottom: 10,
    color: 'rgb(85,85,85)'
  },
  header3: {
    width: 'calc(100%)',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 10,
    color: 'rgb(85,85,85)'
  },
  form: {
    display: 'flex',
    height: 'calc(100%)',
    width: 'calc(100%)',
    justifyContent: 'center',

    alignItems: 'flex-start',

    // width: 'calc(100% - 50px)'
  },
  formInner: {
    width: 'calc(100%)', paddingLeft: 20, paddingRight: 20
  },
};
export default Register;
