import React from "react";
import { Header, Input, Segment, Checkbox, Form, Button, Modal, Image, Grid } from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"
import MaterialTable from 'material-table';
import EditableTable from './editableTable';
import * as Common from './common'

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

const options = {
  headers: headers,
  timeout: 5000
}
var skinType = {'시점':'','주름':'','트러블':'','색소침착':'','모공크기':'', '모공수':'','피부결면포여드름':''
,'피부결':'','면포여드름':'','각질량':'','모공내피지':'','모공주변각질':'','붉음증':'','혈관확장':'' }

var defaultEvals = {/* "정면":skinType, */"이마":skinType,"볼":skinType,"코":skinType,"턱":skinType, }
const valString = [/* "정면", */ "이마",  "볼", "코", "턱"]
var nextBtn = "다음";
class Evaluate extends React.Component {
  state = { id: '', data: [], lastData: [], valCnt: 0, evals: defaultEvals }

  componentDidMount() {
    console.log(this.props.id)
    console.log(this.state.evals)
    var data = this.props.data;
    var evals = defaultEvals;
    if(data !== undefined) {
      evals = data.evals;
    }
    if(this.props.data)
    this.setState({
      ...this.state,
      id: this.props.id,
      data:data,
      evals:evals,
    })
    var sendData = {
      id: this.props.id,
    
    };

    var sendUrl = Common.DBQUERY;//"http://localhost:3005/query";
    axios.post(sendUrl, sendData, options).then(response => {
      console.log(response.data)
      console.log(response.data[0].userName, response.data[0].date)
      // console.log(response.data[1].userName, response.data[1].date)

      this.setState({
        ...this.state,
        data: response.data,
      })
    }).catch(function (error) {
      console.log(error)
    });
  }
  handleNext = () => {
    var nextCnt = this.state.valCnt + 1;
    if( nextCnt >= 4) {
      nextBtn = "다음"//"저장"
    }
    else {
      nextBtn = "다음"

    }
    if (nextCnt > valString.length - 1) {
      var nd = this.state.data
      nd[0].valuated = 'yes'
      var nnd = {...nd[0], evals:this.state.evals};
      console.log(nnd)
      this.props.onDone(nnd)
    } else {
      this.setState({
        ...this.state,
        valCnt: nextCnt,
      })
    }
  }
  handlePrev = () => {
    var nextCnt = this.state.valCnt - 1;
    if (nextCnt < 0) {
      nextCnt = 0;
    }
    this.setState({
      ...this.state,
      valCnt: nextCnt,
    })

  }
  onChange = (e) => {
    console.log(e.target.id, e.target.value)
    console.log(this.state.evals)
    var oldEvals = this.state.evals[valString[this.state.valCnt]];
    console.log(oldEvals)
    var evals = {...this.state.evals, [valString[this.state.valCnt]]:{...oldEvals, [e.target.id]:e.target.value}}
    // var evals = {...oldEvals, ...newEvals}
    this.setState({
      ...this.state,
      evals,
    })
  }
  render() {
    var imgList = [], imgListPrev = [];
    var name = "";
    var number = "";
    var candidateImg = Common.SAMPLEIMG;
    var candidateImgPrev = Common.SAMPLEIMG;
    var candidateFaceImg = Common.SAMPLEIMG;
    var candidatePrevFaceImg = Common.SAMPLEIMG;

    var evalsPrev = defaultEvals;
    console.log(this.state.data)

    if (this.state.data[0] !== undefined) {
      candidateFaceImg = Common.WEBSERVER + this.state.data[0].face;
      imgList = [ this.state.data[0].forehead, 
      this.state.data[0].cheek, this.state.data[0].nose, this.state.data[0].chin]
      name = this.state.data[0].userName;
      number = this.state.data[0].userNumber;
      candidateImg = Common.WEBSERVER + imgList[this.state.valCnt];
    }
    if(this.state.data[1] !== undefined) {
      candidatePrevFaceImg = Common.WEBSERVER + this.state.data[1].face;
      imgListPrev = [ this.state.data[1].forehead, 
      this.state.data[1].cheek, this.state.data[1].nose, this.state.data[1].chin]
      candidateImgPrev = Common.WEBSERVER + imgListPrev[this.state.valCnt];
      evalsPrev = this.state.data[1].evals;
      console.log(evalsPrev)
    }

    return (
      <div style={styles.container}>

        <Grid columns={3} style={{ width: '100%' }}>
          <Grid.Column width={4}>
            <Header>이전 데이터</Header>
            <EditableTable disabled={"disabled"} data={evalsPrev[valString[this.state.valCnt]]}></EditableTable>
            <Header as='h3'>이전 얼굴 이미지</Header>
            <Image src={candidatePrevFaceImg} rounded size='medium' />
          </Grid.Column>

          <Grid.Column stretched width={8} >
            <Header style={{ textAlign: 'center', marginBottom: 0 }} >{name}님({number}) {valString[this.state.valCnt]} 사진 ({this.state.valCnt + 1}/{valString.length})</Header>

            <Segment style={{ width: '100%' }}>
              {/* <UserList></UserList> */}
              {/* <Evaluate></Evaluate> */}
              <Header style={{ textAlign: 'right' }} >현재 데이터{' >>'}</Header>
              <Image src={candidateImg} rounded size='large' />
              <Header style={{ textAlign: 'left' }} >{'<< '}이전 데이터</Header>
              <Image src={candidateImgPrev} rounded size='large' />
              {/* <br /> */}

              <Button primary style={{ marginTop: '10px', width: '100px' }} onClick={this.handlePrev}>이전</Button>
              <Button primary style={{ marginTop: '10px', width: '100px' }} onClick={this.handleNext}>{nextBtn}</Button>

            </Segment>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header>현재 데이터</Header>

            <EditableTable onChange={this.onChange} data={this.state.evals[valString[this.state.valCnt]]}></EditableTable>
            <Header as='h3'>현재 얼굴 이미지</Header>
            <Image src={candidateFaceImg} rounded size='medium' />

          </Grid.Column>
        </Grid>
        {/* <div style={styles.root}> */}
        {/* <EditableTable></EditableTable> */}
        {/* <Image src='/images/a.png' size='small' /> */}
        {/* </div> */}
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%',
  },
};
export default Evaluate;
