import React from 'react';
import MaterialTable from 'material-table';
import { Input, Grid } from 'semantic-ui-react'

var skinType = {'시점':'','주름':'','트러블':'','색소침착':'','모공크기':'', '모공수':''
,'피부결':'','면포여드름':'','각질량':'','모공내피지':'','모공주변각질':'','붉음증':'','혈관확장':'' }

export default function MaterialTableDemo(props) {
  // console.log(props.data)
  // console.log(props.data["A"])
  var myData = skinType;
  if(props.data !== undefined) {
    myData = props.data;
    // console.log(myData)
  }
  const [state, setState] = React.useState({
    columns: [
      { title: '시점', field: 'T' },
      { title: '주름', field: 'A', type: 'numeric' },
      { title: '트러블', field: 'B', type: 'numeric' },
      { title: '색소침착', field: 'C', type: 'numeric' },
      { title: '모공크기', field: 'D', type: 'numeric' },
      { title: '모공수', field: 'D', type: 'numeric' },
      { title: '피부결', field: 'E', type: 'numeric' },
      { title: '면포여드름', field: 'F', type: 'numeric' },
      { title: '각질량', field: 'G', type: 'numeric' },
      { title: '모공내피지', field: 'H', type: 'numeric' },
      { title: '모공주변각질', field: 'I', type: 'numeric' },
      { title: '붉음증', field: 'J', type: 'numeric' },
      { title: '혈관확장', field: 'K', type: 'numeric' },


    ],
    data: [
      { T: '이전', A: 2, B: 4, C: 0, D: 0, E: 0, F: 0, G: 6, H: 0, I: 0, J: 0, K: 0 },
      { T: '현재', A: 0, B: 0, C: 0, D: 0, E: 0, F: 0, G: 0, H: 0, I: 0, J: 0, K: 0 },

    ],
  });

  return (
    <div style={styles.container}>
      <Grid columns={2} style={{width:'100%'}}>
      <Grid.Column width={8} style={{paddingRight:0, paddingLeft:0}}>
        <div style={styles.item}>주름</div>
        <div style={styles.item}>트러블</div>
        <div style={styles.item}>색소침착</div>
        <div style={styles.item}>모공크기</div>
        <div style={styles.item}>모공수</div>
        <div style={styles.item}>피부결</div>
        <div style={styles.item}>면포여드름</div>
        <div style={styles.item}>각질량</div>
        <div style={styles.item}>모공내피지</div>
        <div style={styles.item}>모공주변각질</div>
        <div style={styles.item}>붉음증</div>
        <div style={styles.item}>혈관확장</div>
      </Grid.Column>
      <Grid.Column stretched width={8} style={{width:'100px', paddingLeft:0}}>
      <Input placeholder='' id={"주름"}  value={myData["주름"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"트러블"} value={myData["트러블"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"색소침착"} value={myData["색소침착"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"모공크기"} value={myData["모공크기"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"모공수"} value={myData["모공수"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"피부결"} value={myData["피부결"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"면포여드름"} value={myData["면포여드름"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"각질량"} value={myData["각질량"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"모공내피지"} value={myData["모공내피지"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"모공주변각질"} value={myData["모공주변각질"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"붉음증"} value={myData["붉음증"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />
      <Input placeholder='' id={"혈관확장"} value={myData["혈관확장"]} onChange={props.onChange} disabled={props.disabled} style={styles.inputitem} />

      </Grid.Column>


      </Grid>
      {/* <Grid columns={2} >
      <Grid.Row>
      <Grid.Column width={4}>주름</Grid.Column>
      <Grid.Column width={3}><Input placeholder='' style={{ width: '50px' }} /></Grid.Column>
      </Grid.Row>
      <Grid.Row>
      <Grid.Column width={4}>트러블</Grid.Column>
      <Grid.Column width={3}><Input placeholder='' style={{ width: '50px' }} /></Grid.Column>
      </Grid.Row>
      <Grid.Row>
      <Grid.Column width={4}>색소침착</Grid.Column>
      <Grid.Column width={3}><Input placeholder='' style={{ width: '50px' }} /></Grid.Column>
      </Grid.Row>
      <Grid.Row>
      <Grid.Column>주름</Grid.Column>
      <Grid.Column><Input placeholder='' style={{ width: '50px' }} /></Grid.Column>

      </Grid.Row>
      <Grid.Row>
      <Grid.Column>주름</Grid.Column>
      <Grid.Column><Input placeholder='' style={{ width: '50px' }} /></Grid.Column>

      </Grid.Row>
      </Grid> */}


    </div>
  )
}
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px'
    // background: '#FF00FF',
    // margin: '10px',

  },
  item: {
    padding: 9,
  },
  inputitem: {
    height: '5px',
    width: '45px'
  }
}
