import React from "react";
import { Header, Input, Segment, Checkbox, Form, Button, Modal , Image} from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"
import { common } from "@material-ui/core/colors";
import * as Common from './common'


class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
 
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.root}>

        <Image rounded src={common.SAMPLEIMG} size='small' />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // background: '#FF00FF',
    // margin: '10px',

  },
  checkbox:{
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  checkboxele:{
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // color: 'black',
    // height: 'calc(100%)',
    // width: 'calc(100%)',
    // maxWidth: '1200px',
    // padding: '40px 10px 40px 10px',
    // margin: '10px',
    // alignItems: 'center',
  },
  form: {
    width: 'calc(100% - 50px)',
    minWidth: '400px',
    maxWidth: '500px',

  }
};
export default Detail;
