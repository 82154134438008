
//production server
// const WEBSERVER = "http://3.35.186.89:80"
// const APISERVER = "http://3.35.186.89:3005"

//test server
// const WEBSERVER = "http://3.36.106.60:3007"
// const APISERVER = "http://3.36.106.60:3005"

//local server
// const WEBSERVER = "http://localhost:3007"
// const APISERVER = "http://localhost:3005"

//for dev test
// const APISERVER = "http://localhost:3004"

const WEBSERVER = process.env.REACT_APP_WEBSERVER;
const APISERVER = process.env.REACT_APP_APISERVER;

const DBCOUNT = APISERVER+"/count"
const DBQUERY = APISERVER+"/query"
const DBUPLOAD = APISERVER+"/upload"
const DBUPDATE = APISERVER+"/update"
const DBCANDIDATE = APISERVER+"/listcandidate"
const DBEVALUATED = APISERVER+"/listevaluated"
const DBSEARCH = APISERVER+"/search"
const DBDELETE = APISERVER+"/delete"
const DBQUERYMEMO = APISERVER+"/querymemo"
const DBUPDATEMEMO = APISERVER+"/updatememo"


const SAMPLEIMG = 'https://s3-ap-northeast-2.amazonaws.com/images-kr.girlstyle.com/wp-content/uploads/2018/12/natepann_temp15445512132884586302626327459920.gif'

export {
  APISERVER,
  DBCOUNT,
  DBQUERY,
  DBUPLOAD,
  DBUPDATE,
  DBCANDIDATE,
  DBEVALUATED,
  DBSEARCH,
  SAMPLEIMG,
  WEBSERVER,
  DBDELETE,
  DBQUERYMEMO,
  DBUPDATEMEMO,
}

