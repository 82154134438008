import React, { Component } from 'react'
import { Header, Input, Segment, Checkbox, Form, Button, Modal, Image, Grid } from 'semantic-ui-react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './table.css'
var tableData = [
  { id: '주름', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '트러블', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '색소침착', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '모공크기', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '모공수', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '피부결', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '면포여드름', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '각질량', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '모공내피지', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '모공주변각질', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '붉음증', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
  { id: '혈관확장', /*정면: 0,*/ 이마: 1, 볼: 2, 코: 2, 턱: 3 },
]
class SummaryTable extends Component {
  constructor(props) {
    super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
    console.log(this.props.data)

    var evals = this.props.data.evals;

    var clipData = '';
    var clipDataAll = '';
    tableData.map((key, index) => {
      // var wh = evals['정면'][key.id]
      // tableData[index]['정면'] = wh;
      if(clipData !== '') clipData += '\n'
      var wh = evals['이마'][key.id]
      tableData[index]['이마'] = wh;
      var wh = evals['볼'][key.id]
      tableData[index]['볼'] = wh;
      var wh = evals['코'][key.id]
      tableData[index]['코'] = wh;
      var wh = evals['턱'][key.id]
      tableData[index]['턱'] = wh;
      clipData += tableData[index]['이마'] + '\t' + tableData[index]['볼'] + '\t'
      + tableData[index]['코'] + '\t' + tableData[index]['턱'];
    })

    if(this.props.data.perscribe !== undefined) {
    clipDataAll += clipData+'\n'
    clipDataAll += this.props.data.userName+'\n'
    clipDataAll += this.props.data.userNumber+'\n'
    clipDataAll += this.props.data.perscribe["세안방법"]+'\n'
    clipDataAll += this.props.data.perscribe["보습방법"]+'\n'
    clipDataAll += this.props.data.perscribe["팩/마스크방법"]+'\n'
    clipDataAll += this.props.data.perscribe["처방요약"]+'\n'
    clipDataAll += this.props.memo
    }
    this.state = { //state is by default an object
      students: tableData,
      value: clipData,
      copied: false,
      clipall: clipDataAll,
    }
  }
  onClick = () => {

  }

  renderTableData() {
    return this.state.students.map((student, index) => {
      const { id, /* 정면, */ 이마, 볼, 코, 턱 } = student //destructuring
      return (
        <tr key={id}>
          <td>{id}</td>
          {/* <td>{정면}</td> */}
          <td>{이마}</td>
          <td>{볼}</td>
          <td>{코}</td>
          <td>{턱}</td>
        </tr>
      )
    })
  }

  renderTableHeader() {
    let header = Object.keys(this.state.students[0])
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }

  render() {
    return (
      <div>
        <h1 id='title'>진단 요약</h1>
        <h3 id='title'>피부 자가 진단 : {this.props.data.troubles}</h3>
        <h3 id='title'>전문가 진단표</h3>

        <table id='students'>
          <tbody>
            <tr>{this.renderTableHeader()}</tr>
            {this.renderTableData()}
          </tbody>
        </table>
        {/* <Button onClick={this.onClick}>클립보드 복사</Button> */}
        <CopyToClipboard options={{ format: "text/plain" }}
          text={this.state.value}
          onCopy={() => this.setState({ copied: true })}>
          {/* <span>Copy to clipboard with span</span> */}
          <Button style={{marginTop:10}}>클립보드 복사</Button>

        </CopyToClipboard>
        <CopyToClipboard options={{ format: "text/plain" }}
          text={this.state.clipall}
          onCopy={() => this.setState({ copied: true })}>
          {/* <span>Copy to clipboard with span</span> */}
          <Button style={{marginTop:10}}>클립보드 전체 복사</Button>

        </CopyToClipboard>
      </div>
    )
  }
}

export default SummaryTable //exporting a component make it reusable and this is the beauty of react