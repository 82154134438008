import React from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";
import { Header, Input, Segment, Checkbox, Form, Button, Modal, Image, Grid } from 'semantic-ui-react'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

function Uploader(props) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(props.title)
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    props.onChange(props.title, imageList[0])
  };

  const showImg = (imgurl, onImageRemove) => {
    return (
      <div>
        <BrowserView>
        <div style={{position: "absolute",}}>
          <div style={{position: "relative", top: "0px", left: "0px"}}><img width="150dp" height="150dp" src={imgurl}></img></div>
        </div>
        <div style={{position: "absolute"}}>
          <div style={{position: "relative", top: "0px", left: "120px"}}><Image style={{width:'30px'}} src="/images/closebtn.png" onClick={() => onImageRemove(0)}></Image></div>
        </div>
        </BrowserView>
        <MobileView>
        <div style={{position: "absolute",}}>
          <div style={{position: "relative", top: "0px", left: "0px"}}><Image width="100dp" height="100dp" src={imgurl}></Image></div>
        </div>
        <div style={{position: "absolute"}}>
          <div style={{position: "relative", top: "0px", left: "80px"}}><Image style={{width:'20px'}} src="/images/closebtn.png" onClick={() => onImageRemove(0)}></Image></div>
        </div>
        </MobileView>
      </div>


    )
  }

  return (
    <div className="App">
      {/* {showImg()} */}
      <ImageUploading
        // multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
            // write your building UI
            <div className="upload__image-wrapper">

              <Grid>
                <Grid.Row style={{ paddingLeft: 50, paddingRight: 50 }}>
                  <Grid.Column width={8}>
                    <Image size='small' src={'/images/' + props.imagename} />
                  </Grid.Column>
                  <Grid.Column width={8} >
                    <div style={{alignItems:'center' }}>
                    {imageList.length == 0 && <Image size='small' src='/images/addimg.png' onClick={onImageUpload} />}
                    {/* {imageList !== undefined && imageList.length > 0 && <Image size='small' src={imageList[0].data_url} onClick={() => onImageRemove(0)} />} */}
                    {imageList !== undefined && imageList.length > 0 && showImg(imageList[0].data_url, onImageRemove) }
                    </div>

                  </Grid.Column>
                  <Grid.Column width={8} style={{color:'rgb(119,119,119)'}}>
                    {"ex)" + props.comment}
                  </Grid.Column>
                  <Grid.Column width={8} style={{color:'rgb(119,119,119)'}}>
                    {props.lang=="jp" && "写真をアップロード"}
                    {props.lang=="ru" && "мое фото"}
                    {props.lang=="ko" && "내사진"}
                    {props.lang=="en" && "My Photos"}
                    {props.lang=="vn" && "Ảnh của tôi"}
                    {props.lang=="cn" && "我的照片"}
                  </Grid.Column>
                </Grid.Row>
              </Grid>



              {/* <div style={{ display: 'flex', flexDirection: 'column', }}>
                <Image src='images/a.png' size='small' wrapped />
            <div>adfasfs</div>
            </div>
            &nbsp;
              <Image src='images/a.png' size='small' wrapped onClick={onImageUpload} /> */}

              {/* <Button
              color='green'
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              사진 선택
            </Button> */}
            &nbsp;
              {/* <Button onClick={onImageRemoveAll} color='red'>사진 삭제</Button> */}
              {/* {imageList.map((image, index) => ( */}
              {/* <div key={index} className="image-item" style={{ marginTop: 10 }}> */}
              {/* // <img src={image.data_url} alt="" width="200" /> */}
              {/* <div className="image-item__btn-wrapper">
                  <Button onClick={() => onImageUpdate(index)}>Update</Button>
                  <Button onClick={() => onImageRemove(index)}>Remove</Button>
                </div> */}
              {/* </div> */}
              {/* ))} */}
            </div>
          )}
      </ImageUploading>
    </div>
  );
}

export default Uploader;
