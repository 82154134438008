import React from "react";
import { Header, Input, Segment, Checkbox, Form, Button, Modal } from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"
const id = "admin";
const pw = "drk-apfkf-499^^";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      password: '',

    };
  }
  onChange = (e) => {
    var input = ""
    var value = e.target.value
    if (e.target.id === "id")
      input = "id"
    else if (e.target.id === "password") {
      input = "password"

    }
    console.log(value)
    this.setState({
      ...this.state,
      [input]: value,
    })
  }
  handleSubmit = (e) => {
    console.log(this.state.id, this.state.password)
    if( this.state.id === id && this.state.password === pw) {
      console.log("logged In")
      this.props.logged(true)
    } 
    
  }
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.root}>
          <div style={styles.form}>
            <Form autoComplete="new-password">
              <Form.Field>
                <label>아이디</label>
                <input id="id" value={this.state.id} onChange={this.onChange} placeholder='아이디' />
              </Form.Field>
              <Form.Field>
                <label>비밀번호</label>
                <input type='password' id="password" value={this.state.password} onChange={this.onChange} placeholder='비밀번호' />
              </Form.Field>

              <Button type='submit' onClick={this.handleSubmit} style={{width:100}}>다음</Button>
            </Form>

          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // background: '#FF00FF',
    // margin: '10px',

  },
  checkbox:{
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  checkboxele:{
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // color: 'black',
    // height: 'calc(100%)',
    // width: 'calc(100%)',
    // maxWidth: '1200px',
    // padding: '40px 10px 40px 10px',
    // margin: '10px',
    // alignItems: 'center',
  },
  form: {
    width: 'calc(100% - 50px)',
    minWidth: '400px',
    maxWidth: '500px',

  }
};
export default Login;
