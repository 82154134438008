import React from "react";
import { Header, Input, Segment, Checkbox, Form, Modal, Dimmer, Loader } from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"
import Uploader from "./Uploader"
import moment from 'moment'
import { Button, Radio } from 'antd';
import 'antd/dist/antd.css';
import * as Common from './common'
const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}
const options = {
  headers: headers,
  timeout: 30000
}
const maxNumber = 69;


class PicUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buyerName: '',
      buyerNumber: '',
      userName: '',
      userNumber: '',
      check: false,
      modalOpen: false,
      modalContent: "",
      face: '',
      forehead: '',
      nose: '',
      cheek: '',
      chin: '',
      btnloading:false,
      closingModel:false,
      chkList:'',
    };
  }
  componentDidMount() {
    if (this.props.location.state !== undefined) {
      console.log("valid access")

      console.log(this.props.location.state.buyerName)
      console.log(this.props.location.state.userName)

      this.setState({
        ...this.state,
        buyerName: this.props.location.state.buyerName,
        buyerNumber: this.props.location.state.buyerNumber,
        userName: this.props.location.state.userName,
        userNumber: this.props.location.state.userNumber,
        chkList: this.props.location.state.chkList,
      })

    } else {

      console.log("invalid access")

    }
  }


  ModalNotice = () => (
    <Modal
      // trigger={<Button>Show Modal</Button>}
      // trigger={<Button>Show Modal</Button>}
      open={this.state.modalOpen}
      header='Reminder!'
      content={this.state.modalContent}
      onActionClick={this.handleModal}
      actions={[{ key: 'done', content: 'Done', positive: true }]}
    />
  )

  handleModal = (e) => {
    var txt = e.target.textContent;

    if (txt === "Done")
      console.log(e.target.textContent)

    this.setState({
      ...this.state,
      modalOpen: false,
    })
    if(this.state.closingModel === true) {

    }
  }
  goBack = () => {
    this.props.history.push({
      pathname: '/vn',
      // search: '?key='+parsed.key,
      // state: { valid: parsed.key }
    })
  }
  uploadDone = () => {
    this.setState({
      ...this.state,
      btnloading:false,
      // modalOpen: true,
      // closingModel:true,
      // modalContent: "사진이 모두 업로드 되었습니다.",
    })
    this.props.history.push({
      pathname: '/done-vn',
      // search: '?key='+parsed.key,
      // state: { valid: parsed.key }
    })
  }
  handleSubmit = (e) => {
    // console.log(this.state.name)
    console.log(this.state.face)

    if (this.state.face === '' || this.state.nose === '' || this.state.cheek === ''
      || this.state.chin === '' || this.state.forehead === '') {
      this.setState({
        ...this.state,
        modalOpen: true,
        modalContent: "Please attach all the pictures.",
      })
      return;
    }

    console.log(moment().format())
    var sendUrl = Common.DBUPLOAD; //"http://localhost:3005/upload"
    var images = {
      face: this.state.face, forehead: this.state.forehead,
      nose: this.state.nose, cheek: this.state.cheek, chin: this.state.chin
    }
    var sendData = {
      "buyerName": this.state.buyerName,
      "buyerNumber": this.state.buyerNumber,
      "userName": this.state.userName,
      "userNumber": this.state.userNumber,
      "date": moment().format(),
      "images": images,
      "troubles": this.state.chkList,
      // "area": title,
      // "image": image,
    };
    this.setState({
      ...this.state,
      btnloading:true,
    })
    axios.post(sendUrl, sendData, options
    ).then(response => {
      console.log(response.data)
      setTimeout(() => { this.uploadDone() }, 2000);
    }).catch((error) => {
      console.log(error)
      this.setState({
        ...this.state,
        modalOpen: true,
        btnloading:false,
        modalContent: "사진 전송이 실패하였습니다. 다시 시도 해주세요. 전송 실패 반복 시 카카오톡플러스채널 \"피부박사강박사\"로 문의해주세요.",
      })

    });
    // this.checkValidation(this.state.name, this.state.number)
  }
  onChange = (title, image) => {
    console.log(title, image);
    this.setState({
      ...this.state,
      [title]: image,
    })
  };

  render() {

    return (
      <div style={styles.container}>
        <div style={styles.root}>
        <Dimmer active={this.state.active}>
              <Loader size='big'>Loading</Loader>
            </Dimmer>
          <div style={styles.header}>
            <Header as='h2' style={{ color: 'rgb(85,85,85)' }}>{this.state.userName}, Rất vui khi được gặp bạn</Header>
          </div>
          {/* <Segment raised style={{ marginLeft: 20, marginRight: 20 }}> */}
          {/* <Header as='h4'>{this.props.location.state.name}님, 반갑습니다.</Header> */}
          <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 20, textAlign: 'left', fontSize: '1.3em', color: 'rgb(119,119,119)' }}>Gắn ống kính bạn nhận được trong sản phẩm vào camera sau của điện thoại thông minh và chụp ảnh phóng đại trán, mũi, má và cằm. Vui lòng chụp ảnh selfie phía trước bằng camera trước mà không cần gắn ống kính.</div>
          {/* </Segment> */}
          <div style={styles.form}>
            <Form>
              <Header as='h4' style={{ color: 'rgb(119,119,119)' }}>Selfie phía trước</Header>
              <div style={{ marginBottom: 0 }}>
                <Uploader onChange={this.onChange} title={'face'} comment={'Chính diện khuôn mặt'} imagename={'face.png'} lang={"vn"}></Uploader>
              </div>
              <Header as='h4' style={{ color: 'rgb(119,119,119)' }}>Ảnh trán</Header>
              <div style={{ marginBottom: 0 }}>
                <Uploader onChange={this.onChange} title={'forehead'} comment={'Giữa trán'} imagename={'forehead.png'} lang={"vn"}></Uploader>
              </div>
              <Header as='h4' style={{ color: 'rgb(119,119,119)' }}>Ảnh mũi</Header>
              <div style={{ marginBottom: 0 }}>
                <Uploader onChange={this.onChange} title={'nose'} comment={'Lỗ mũi'} imagename={'nose.png'} lang={"vn"}></Uploader>
              </div>
              <Header as='h4' style={{ color: 'rgb(119,119,119)' }}>Ảnh má</Header>
              <div style={{ marginBottom: 0 }}>
                <Uploader onChange={this.onChange} title={'cheek'} comment={'Giữa má'} imagename={'cheek.png'} lang={"vn"}></Uploader>
              </div>
              <Header as='h4' style={{ color: 'rgb(119,119,119)' }}>Ảnh hàm</Header>
              <div style={{ marginBottom: 0 }}>
                <Uploader onChange={this.onChange} title={'chin'} comment={'Hàm dưới'} imagename={'chin.png'} lang={"vn"}></Uploader>
              </div>
              {/* <Button type='submit' onClick={this.handleSubmit}>이전</Button> */}

              {/* <Button primary type='submit' onClick={this.handleSubmit} style={{ marginLeft: 20, width: 100 }}>완료</Button> */}

              <Button onClick={this.goBack} shape="round" size='large' style={{ color: '#555555', width: 'calc(100%/2)', maxWidth:120, borderWidth: '2px', borderColor: '#555555' }}> Trước </Button>

              <Button onClick={this.handleSubmit} type="text" shape="round" size='large' style={{ backgroundColor: '#555555', color: 'white', marginLeft: 20, width: 'calc(100%/2)', maxWidth:120, }} loading={this.state.btnloading}> Hoàn thành </Button>

            </Form>
            {this.ModalNotice()}
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    // background: '#FF00FF',
    // margin: '10px',

  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  checkboxele: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',

    color: 'black',
    height: 'calc(100%)',
    width: 'calc(100%)',
    maxWidth: '500px',
    padding: '40px 10px 40px 10px',
    margin: '10px',
    alignItems: 'center',
  },
  form: {
    width: 'calc(100%)'
  },
  header: {
    width: 'calc(100%)',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 20,
    marginBottom: 20
  },
  button: {
    backgroundColor: '#4CAF50', /* Green */
    border: 'none',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '30%',
    width: '100px'
  },
  headerI: {
    color: 'rgb(85,85,85)',
    marginTop: 0
  },
  text: {
    color: 'rgb(119,119,119)'
  }
};
export default PicUploader;
