import React from "react";
import { Header, Input, Segment, Checkbox, Form, Button, Modal, Image, Grid } from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"
import SummaryTable from './summarytable'
import * as Common from './common'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

const options = {
  headers: headers,
  timeout: 5000
}
class Perscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facewash: '',
      mist: '',
      mask: '',
      daily: '',
      modalContent:'',
      modalOpen:false,
      memo:'',
      memoid:'',
      number:'',
      memoprops:'',
      copied: false,
    };
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log(nextProps.memo)
  //   return {
  //    memoprops:nextProps.memo,
  //   };
  //  }
  componentDidMount() {
    console.log("!!!! mode = ", this.props.mode)

    console.log("!!!!", this.props.data)

    console.log("!!!!", this.props.memo)

    
    if (this.props.mode === 'detail') {
      var facewash = this.props.data.perscribe['세안방법'] === undefined ? '' : this.props.data.perscribe['세안방법']
      var mist = this.props.data.perscribe['보습방법'] === undefined ? '' : this.props.data.perscribe['보습방법']
      var mask = this.props.data.perscribe['팩/마스크방법'] === undefined ? '' : this.props.data.perscribe['팩/마스크방법']
      var daily = this.props.data.perscribe['처방요약'] === undefined ? '' : this.props.data.perscribe['처방요약']

      this.setState({
        ...this.state,
        facewash: facewash,
        mist: mist,
        mask: mask,
        daily: daily,
        memo:this.props.memo.userMemo,
        memoid:this.props.memo._id,
        number:this.props.memo.number,
      })
    }
    // var memo = this.props.memo;
    // this.setState({
    //   ...this.state,
    //   memo: memo,

    // })
  }
  ModalNotice = () => (
    <Modal
      // trigger={<Button>Show Modal</Button>}
      // trigger={<Button>Show Modal</Button>}
      open={this.state.modalOpen}
      header='Reminder!'
      content={this.state.modalContent}
      onActionClick={this.handleModal}
      actions={[{ key: 'delete', content: '삭제', color:'red' },{ key: 'cancel', content: '취소', positive: true }]}

    />
  )

  handleModal = (e) => {
    var txt = e.target.textContent;
    console.log(txt)
    if (txt === "삭제") {
      var data = {id:this.props.data._id};
      var sendUrl = Common.DBDELETE; //"http://localhost:3005/update";
      console.log(data)
      // var nd = { id: data }
  
      axios.post(sendUrl, data, options).then(response => {
        this.props.onBack("clear");
      }).catch(function (error) {
        console.log(error)
      });
    } else {
      this.setState({
        ...this.state,
        modalOpen: false,
        modalContent:''
      })
    }
  }
  onDelete = () => {
    console.log("onDelete")
    this.setState({
      ...this.state,
      modalOpen: true,
      modalContent:'정말 삭제 하시겠습니까?'
    })
  }

  onDone = () => {
    var data = this.props.data;
    var perscribe = { '세안방법': this.state.facewash, '보습방법': this.state.mist, '팩/마스크방법': this.state.mask, '처방요약': this.state.daily }
    data.perscribe = perscribe;
    var sendUrl = Common.DBUPDATE; //"http://localhost:3005/update";
    console.log(data)
    var nd = { data: data }
    // this.props.onDone();

    axios.post(sendUrl, nd, options).then(response => {
      {
        var memoData = {
          data:{
          userMemo: this.props.memo.userMemo,
          number:this.props.memo.number,
          // _id:this.state.memoid,
        }
        };
        console.log("!!!", memoData)
        var memoUrl = Common.DBUPDATEMEMO;//"http://localhost:3005/querymemo";

        axios.post(memoUrl, memoData, options).then(response => {
          console.log("!!! memo updated")
          this.props.onDone();

        }).catch(function (error) {
          console.log(error)
        });

      }
      // this.props.onDone();
    }).catch(function (error) {
      console.log(error)
    });
  }
  onModify = () => {
    var data = this.props.data;
    var perscribe = { '세안방법': this.state.facewash, '보습방법': this.state.mist, '팩/마스크방법': this.state.mask, '처방요약': this.state.daily }
    data.perscribe = perscribe;
    var sendUrl = Common.DBUPDATE; //"http://localhost:3005/update";
    console.log(data)
    var nd = { data: data }
    // this.props.onDone();

    axios.post(sendUrl, nd, options).then(response => {
      {
        var memoData = {
          data:{
          userMemo: this.props.memo.userMemo,
          number:this.props.memo.number,
          // _id:this.state.memoid,
        }
        };
        console.log("!!!", memoData)
        var memoUrl = Common.DBUPDATEMEMO;//"http://localhost:3005/querymemo";

        axios.post(memoUrl, memoData, options).then(response => {
          console.log("!!! memo updated")
          this.props.onModify();

        }).catch(function (error) {
          console.log(error)
        });

      }



      // this.props.onModify();
    }).catch(function (error) {
      console.log(error)
    });
  }
  onChange = (e) => {
    var input = e.target.id;
    var value = e.target.value;
    console.log(input, value)

    this.setState({
      ...this.state,
      [input]: value,
    })
  }
  howtoForm = () => {
    return (
      <Form style={{ width: '100%' }}>
        <Form.TextArea label='세안방법' id='facewash' onChange={this.onChange} value={this.state.facewash} placeholder='' style={{ width: '100%' }} />
        <Form.TextArea label='보습방법' id='mist' onChange={this.onChange} value={this.state.mist} placeholder='' />
        <Form.TextArea label='팩/마스크방법' id='mask' onChange={this.onChange} value={this.state.mask} placeholder='' />
        <Form.TextArea label='처방요약' id='daily' onChange={this.onChange} value={this.state.daily} placeholder='' />
        <Form.TextArea label='메모' id='memo' onChange={this.props.onChangeMemo} value={this.props.memo.userMemo} placeholder='' />

      </Form>
    )
  }
  render() {
    return (
      <div style={styles.container}>
        <Grid style={{ width: '100%' }}>
          <Grid.Row style={styles.row}>
            <Image rounded src={Common.WEBSERVER + this.props.data.face} label="정면" size='small' style={styles.img} />
            <Image rounded src={Common.WEBSERVER + this.props.data.forehead} label="이마" size='small' style={styles.img} />
            <Image rounded src={Common.WEBSERVER + this.props.data.cheek} label="볼" size='small' style={styles.img} />
            <Image rounded src={Common.WEBSERVER + this.props.data.nose} label="코" size='small' style={styles.img} />
            <Image rounded src={Common.WEBSERVER + this.props.data.chin} label="턱" size='small' style={styles.img} />

          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <SummaryTable data={this.props.data} memo={this.props.memo.userMemo}></SummaryTable>
            </Grid.Column>
            <Grid.Column>
              {this.howtoForm()}

            </Grid.Column>

          </Grid.Row>
          {/* <Grid.Row>
            {this.howtoForm()}
          </Grid.Row> */}
          <Grid.Row style={styles.row}>
            <Button primary size='large' style={{ marginTop: '10px', width: '100px' }} onClick={this.props.onBack}>이전</Button>
            {this.props.mode === 'perscribe' && <Button onClick={this.onDone} primary size='large' style={{ marginTop: '10px', width: '100px' }}>저장</Button>}

            {this.props.mode === 'detail' && <Button color='red' onClick={this.onModify} size='large' style={{ marginTop: '10px', width: '100px' }}>수정</Button>}

            <Button color='red' size='large' style={{ marginTop: '10px', width: '100px' }} onClick={this.onDelete}>삭제</Button>
          </Grid.Row>
        </Grid>
        {this.ModalNotice()}
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // background: '#FF00FF',
    // margin: '10px',

  },
  img: {
    marginRight: '10px',


  },
  row: {
    display: 'flex',
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  checkboxele: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // color: 'black',
    // height: 'calc(100%)',
    // width: 'calc(100%)',
    // maxWidth: '1200px',
    // padding: '40px 10px 40px 10px',
    // margin: '10px',
    // alignItems: 'center',
  },
  form: {
    width: 'calc(100% - 50px)',
    minWidth: '400px',
    maxWidth: '500px',

  }
};
export default Perscribe;
