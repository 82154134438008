// import { Header, Input, Segment, Checkbox, Form, Button, Modal , Image} from 'semantic-ui-react'
import { findByLabelText } from "@testing-library/react";
import axios from "axios"
import React, { Component } from 'react'
import { Input, Label, Menu, Grid, Image, Container, Segment } from 'semantic-ui-react'
import Evaluate from './evaluate'
import UserList from './userlist'
import { Row, Col, Divider } from 'antd';
import 'antd/dist/antd.css';
import Perscribe from './prescribe'
import Detail from './detail'
import * as Common from './common'

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

const options = {
  headers: headers,
  timeout: 5000
}
export default class AdminMenu extends Component {
  state = { activeItem: 'new', selectedId: '', evaluated: false, countCandidate: 0, countEvaluated: 0, data: undefined, memo: {_id:"0", userMemo:"none"} }

  componentDidMount() {
    // var sendUrl = "http://localhost:3005/count"
    var sendUrl = Common.DBCOUNT;

    axios.get(sendUrl).then(response => {
      console.log(response.data)
      this.setState({
        ...this.state,
        countCandidate: response.data[1],
        countEvaluated: response.data[0],
      })
    }).catch(function (error) {
      console.log(error)
    });
  }

  handleItemClick = (e, { name }) => {

    this.setState({ activeItem: name, selectedId: '', data: undefined, evaluated: false, })
  }

  menu = () => {
    const { activeItem } = this.state

    return (
      <Menu vertical>
        <Menu.Item
          name='new'
          active={activeItem === 'new'}
          onClick={this.handleItemClick}
        >
          <Label color='teal'>{this.state.countCandidate}</Label>
        신규 진단 접수
      </Menu.Item>

        <Menu.Item
          name='past'
          active={activeItem === 'past'}
          onClick={this.handleItemClick}
        >
          <Label>{this.state.countEvaluated}</Label>

        과거 진단 내역
      </Menu.Item>

        {/* <Menu.Item
          name='updates'
          active={activeItem === 'updates'}
          onClick={this.handleItemClick}
        >
          <Label>1</Label>
        Updates
      </Menu.Item> */}
        {/* <Menu.Item>
          <Input icon='search' placeholder='Search mail...' />
        </Menu.Item> */}
      </Menu>
    )
  }

  userSelect = (id) => {
    console.log(id)


    console.log("!!!!", this.state.activeItem)
 

    if (this.state.activeItem === 'past') {
      //detail
      var sendData = {
        id: id,
      };

      var sendUrl = Common.DBQUERY;//"http://localhost:3005/query";
      axios.post(sendUrl, sendData, options).then(response => {
        console.log(response.data)
        console.log(response.data[0].userName, response.data[0].date)
        // console.log(response.data[1].userName, response.data[1].date)

        //todo : to query memo by usernumber
        {
          var memoData = {
            number: response.data[0].userNumber,
          };
          var memoUrl = Common.DBQUERYMEMO;//"http://localhost:3005/querymemo";
          axios.post(memoUrl, memoData, options).then(response => {
            console.log("!!! memo = ",  response.data)
            var memodata = response.data;
            if(response.data === "") {
              console.log('udududu')
              memodata = {
                number:memoData.number,
                // _id:"0",
                userMemo: "none",
              }
            }
            console.log("!!!2 memo = ",  memodata)
            this.setState({
              ...this.state,
              memo : memodata,
            })
          }).catch(function (error) {
            console.log(error)
          });
        }

        this.setState({
          ...this.state,
          data: response.data[0],
          selectedId: id,
        })
      }).catch(function (error) {
        console.log(error)
      });
    } else {
      this.setState({
        ...this.state,
        selectedId: id,
      })
    }

  }
  onEvalDone = (data) => {

        //todo : to query memo by usernumber
        {
          var memoData = {
            number: data.userNumber,
          };
          var memoUrl = Common.DBQUERYMEMO;//"http://localhost:3005/querymemo";
          axios.post(memoUrl, memoData, options).then(response => {
            console.log("!!! memo = ",  response.data)
            var memodata = response.data;
            if(response.data === "") {
              console.log('udududu')
              memodata = {
                number:memoData.number,
                // _id:"0",
                userMemo: "none",
              }
            }
            console.log("!!!2 memo = ",  memodata)
            this.setState({
              ...this.state,
              memo : memodata,
            })
          }).catch(function (error) {
            console.log(error)
          });
        }




    this.setState({
      ...this.state,
      evaluated: true,
      data: data,
    })
    console.log(this.state.selectedId)
    console.log(data)
  }
  onPerscribeDone = () => {
    this.setState({ activeItem: 'new', selectedId: '', data: undefined, evaluated: false, })

    // this.props.history.push({
    //   pathname: '/admin',
    //   // search: '?key='+parsed.key,
    //   // state: { valid: parsed.key }
    // })
  }
  onDetailModify = () => {
    this.setState({ activeItem: 'past', selectedId: '', data: undefined, evaluated: false, })
  }
  onBack = (type) => {
    console.log(this.state.data)
    if (type === 'clear') {
      this.setState({
        ...this.state,
        activeItem: 'new',
        selectedId: '', 
        data: undefined, 
        evaluated: false,
        evaluated: false,
      })
    } else {
      this.setState({
        ...this.state,
        evaluated: false,
      })
    }
  }
  onExit = () => {
    this.setState({ activeItem: 'past', selectedId: '', data: undefined, evaluated: false, })
  }

  onChangeMemo = (e) => {
    var input = e.target.id;
    var value = e.target.value;
    console.log(input, value)

    var newMemo = this.state.memo;
    newMemo.userMemo = value;
    this.setState({
      ...this.state,
      memo: newMemo,
    })
  }
  render() {
    const { activeItem } = this.state
    var mode = this.state.activeItem;
    var evaluated = this.state.evaluated;
    var selected = this.state.selectedId;

    var listMode = 'candidate'
    // var evalMode = 'evalute'
    if (mode === 'past' && selected === '') {
      listMode = 'all'
    }
    if (mode === 'new' && selected !== '') {
      listMode = 'evaluate'
    }
    if (mode === 'new' && selected !== '' && evaluated) {
      listMode = 'perscribe'
    }
    if (mode === 'past' && selected !== '') {
      listMode = 'detail'
    }
    console.log(listMode)
    return (
      <div style={styles.container}>
        {/* <Row>
      <Col flex="100px">100px</Col>
      <Col flex="auto">Fill Rest</Col>
    </Row> */}
        <Grid columns={2} style={{ width: '100%' }}>
          <Grid.Column width={3}>
            {this.menu()}
          </Grid.Column>

          <Grid.Column stretched width={13} >
            <Segment style={{ width: '100%' }}>
              {listMode === 'candidate' && <UserList type={"candidate"} onSelect={(id) => this.userSelect(id)}></UserList>}
              {listMode === 'all' && <UserList type={"all"} onSelect={(id) => this.userSelect(id)}></UserList>}
              {listMode === 'evaluate' && <Evaluate id={this.state.selectedId} data={this.state.data} onDone={this.onEvalDone} memo={this.state.memo} onChangeMemo={this.onChangeMemo}></Evaluate>}
              {listMode === 'perscribe' && <Perscribe data={this.state.data} onBack={this.onBack} mode='perscribe' onDone={this.onPerscribeDone} memo={this.state.memo} onChangeMemo={this.onChangeMemo}></Perscribe>}
              {listMode === 'detail' && <Perscribe data={this.state.data} onBack={this.onExit} mode='detail' onDone={this.onPerscribeDone} onModify={this.onDetailModify} memo={this.state.memo} onChangeMemo={this.onChangeMemo}></Perscribe>}

              {/* <Evaluate></Evaluate> */}
              {/* <Perscribe></Perscribe> */}
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
};
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
    // justifyContent: 'flex-start',
    // alignItems: 'left',
    // background: '#FF00FF',
    // margin: '10px',

  },
}
// export default AdminMain;
