import React from "react";
import { Icon, Label, Menu, Table, Form, Button, Input } from 'semantic-ui-react'
import axios from "axios"
import * as Common from './common'

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

const options = {
  headers: headers,
  timeout: 5000
}

class Userlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userlist: [],
      search:'',
    };
  }
  componentDidMount() {
    console.log("!!!!! mount")

    var type = this.props.type
    var sendUrl = Common.DBCANDIDATE;//"http://localhost:3005/listcandidate"

    if (type !== "candidate") return;
      // sendUrl = "http://localhost:3005/listevaluated"


    console.log(type, sendUrl)
    axios.get(sendUrl).then(response => {
      console.log(response.data)
      this.setState({
        ...this.state,
        userlist: response.data,
      })
    }).catch(function (error) {
      console.log(error)
    });
  }

  onClick = (id) => {
    console.log("clicked", id)
    this.props.onSelect(id);
  }
  onSearch = () => {
    console.log("onSearch")
    console.log(this.state.search)
    var sendData = {
      item: this.state.search,
    };
    var sendUrl = Common.DBSEARCH;//"http://localhost:3005/search";
    axios.post(sendUrl, sendData, options).then(response => {
      // console.log(response.data)
      // console.log(response.data[0].userName, response.data[0].date)
      // console.log(response.data[1].userName, response.data[1].date)
      this.setState({
        ...this.state,
        userlist: response.data,
      })
      console.log(response.data)
    }).catch(function (error) {
      console.log(error)
    });
  }
  onSearchLast = () => {
    console.log("onSearchLast 20")
    console.log(this.state.search)

    var sendUrl = Common.DBEVALUATED;//"http://localhost:3005/listevaluated";
    axios.get(sendUrl, options).then(response => {
      // console.log(response.data)
      // console.log(response.data[0].userName, response.data[0].date)
      // console.log(response.data[1].userName, response.data[1].date)
      this.setState({
        ...this.state,
        userlist: response.data,
      })
      console.log(response.data)
    }).catch(function (error) {
      console.log(error)
    });
  }
  onChange = (e) => {
    var input = e.target.id;
    var value = e.target.value;
    console.log(input, value)

    this.setState({
      ...this.state,
      search: value,
    })
  }
  tableItem = (user) => {
    console.log(user._id)
    return (
      <Table.Row onClick={() => this.onClick(user._id)} id={user._id}>
        <Table.Cell>{user.buyerName}</Table.Cell>
        <Table.Cell>{user.buyerNumber}</Table.Cell>
        <Table.Cell>{user.userName}</Table.Cell>
        <Table.Cell>{user.userNumber}</Table.Cell>
        <Table.Cell>{user.date}</Table.Cell>
        <Table.Cell>{user.valuated}</Table.Cell>
      </Table.Row>
    )
  }
  searchBar = () => {
    if(this.props.type === 'candidate') {
      return ;
    }else 
    return (
      <div>
      <Form>
        <Form.Field>
          <label>검색</label>
          <Input placeholder='검색' value={this.state.search} onChange={this.onChange} />
        </Form.Field>
        <Button type='submit' onClick={this.onSearch}>검색</Button>
        <Button type='submit' onClick={this.onSearchLast}>최근 20개 보기</Button>

      </Form>
    </div>
    )
  }
  tableUser = () => {
    var userlist = this.state.userlist;
    var raws = userlist.map(item => {
      console.log(item)
      return this.tableItem(item);
    })
    console.log(raws)

    return (
      <div style={{ width: '100%' }}>
        {this.searchBar()}
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>구매자</Table.HeaderCell>
              <Table.HeaderCell>구매자번호</Table.HeaderCell>
              <Table.HeaderCell>사용자</Table.HeaderCell>
              <Table.HeaderCell>사용자번호</Table.HeaderCell>
              <Table.HeaderCell>업로드날짜</Table.HeaderCell>
              <Table.HeaderCell>진단</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {raws}
          </Table.Body>

        </Table>
      </div>
    )
  }
  render() {
    return (
      <div style={styles.container}>
        {this.tableUser()}
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    // background: '#FF00FF',
    // margin: '10px',

  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  checkboxele: {
    display: 'flex',
    flexDirection: 'column',
    // border:2,

    // flexDirection: 'row',
    // justifyContent: 'start',
    alignItems: 'start',
  },
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // color: 'black',
    // height: 'calc(100%)',
    // width: 'calc(100%)',
    // maxWidth: '1200px',
    // padding: '40px 10px 40px 10px',
    // margin: '10px',
    // alignItems: 'center',
  },
  form: {
    width: 'calc(100% - 50px)',
    minWidth: '400px',
    maxWidth: '500px',

  }
};
export default Userlist;
