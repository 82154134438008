import React from 'react';
import logo from './logo.svg';
import './App.css';
import Register from './register';
import RegisterJP from './register-jp';

import PicUploader from './PicUploader'
import PicUploaderJP from './PicUploader-jp'
import DoneJP from './done-jp'

import RegisterRU from './register-ru';
import PicUploaderRU from './PicUploader-ru'
import DoneRU from './done-ru'

import RegisterEN from './register-en';
import PicUploaderEN from './PicUploader-en'
import DoneEN from './done-en'

import RegisterVN from './register-vn';
import PicUploaderVN from './PicUploader-vn'
import DoneVN from './done-vn'

import RegisterCN from './register-cn';
import PicUploaderCN from './PicUploader-cn'
import DoneCN from './done-cn'


import { Route, Link, BrowserRouter } from 'react-router-dom'
import Admin from './admin'
import Done from './done'
function App() {
  return (
    
    <BrowserRouter>
      <div className="App">
        <Route exact path={"/"} component={Register} />
        <Route exact path={"/pic"} component={PicUploader} />
        <Route exact path={"/done"} component={Done} />

        <Route exact path={"/jp"} component={RegisterJP} />
        <Route exact path={"/pic-jp"} component={PicUploaderJP} />
        <Route exact path={"/done-jp"} component={DoneJP} />

        <Route exact path={"/ru"} component={RegisterRU} />
        <Route exact path={"/pic-ru"} component={PicUploaderRU} />
        <Route exact path={"/done-ru"} component={DoneRU} />

        <Route exact path={"/en"} component={RegisterEN} />
        <Route exact path={"/pic-en"} component={PicUploaderEN} />
        <Route exact path={"/done-en"} component={DoneEN} />

        <Route exact path={"/vn"} component={RegisterVN} />
        <Route exact path={"/pic-vn"} component={PicUploaderVN} />
        <Route exact path={"/done-vn"} component={DoneVN} />

        <Route exact path={"/cn"} component={RegisterCN} />
        <Route exact path={"/pic-cn"} component={PicUploaderCN} />
        <Route exact path={"/done-cn"} component={DoneCN} />

        <Route exact path={"/admin"} component={Admin} />
      </div>
    </BrowserRouter>
  );
}
export default App;
